import React from "react"
import { graphql } from "gatsby"
import Layout2 from "../components/layout2"
import Image from "../components/Image"
import getMetatags from "../util/getMetatags"
import Seo from "../components/Seo"
import { relativeToAbsulote } from "../util/common"

function BookADemo({ data: { nodeBookADemo } }) {

  const {
    //path,
    trustedByTitle,
    relationships,
    calendarUrl,
    isCalendarMode
  } = nodeBookADemo

  const { left, thankyou, trustedBy } = relationships

  const seo = getMetatags(
    nodeBookADemo.metatag,
    nodeBookADemo.relationships.OGImage?.localFile?.publicURL
  )

  seo.meta.push({
    name: "robots",
    content: "noindex, nofollow"
  })

  left.trustedBy = trustedBy
  left.trustedByTitle = trustedByTitle


  return (
    <Layout2
      side={<SideThankYou thankyou={thankyou} />}
    >
      <Seo {...seo} />
      <MainThankYou thankyou={thankyou} calendarUrl={calendarUrl} isCalendarMode={isCalendarMode} />
    </Layout2>
  )
  

}


const MainThankYou = ({ thankyou, calendarUrl, isCalendarMode }) => {

  return (
    <div className="lg:pt-12 lg:px-12 pt-8 px-8 pb-24 ">
      <h1 className="text-5xl py-8 leading-[3.5rem]">{thankyou.title}</h1>
      <p className="text-3xl pb-12 font-bold">{thankyou.description}</p>
      <div className="text-2xl pb-4" dangerouslySetInnerHTML={{ __html: relativeToAbsulote(thankyou?.description_html?.processed) }} />

      { !isCalendarMode && <iframe title="Calendar" src={calendarUrl} width={'100%'} height={'810px'} className="" /> }
    </div>
  )
}

const SideThankYou = ({ thankyou }) => {

  return (
    <div className="container lg:p-20 p-6 bg-blue h-[100%]">
      <div className=" text-grey py-12 px-10 rounded-xl">
        <Image image={thankyou.relationships.image} alt={thankyou.image.alt} />
      </div>
    </div >
  )
}

export default BookADemo

export const query = graphql`
  query bookADemo2b($id: String!) {
    nodeBookADemo(id: { eq: $id }) {
      id
      title
      trustedByTitle: field_title2
      path {
        alias
      }
      showCTA: field_show_book_demo

      testimonialShow : field_show_testimonials
      testimonialTitle : field_title_5

      metatag {
        attributes {
          content
          name
        }
        tag
      }
      isCalendarMode: field_use_calendar
      calendarUrl:field_title
      title1: field_title3
      title2: field_title4
      relationships {
        left: field_hero {
          ...ParagraphGenericBlock
        }
        thankyou: field_paragraph_single{
          ...ParagraphGenericBlock
        }
        hubspot: field_hubspot {
          ...ParagraphHubspotContent
        }
        trustedBy: field_paragraph_repeter {
          image: field_image {
            title
            alt
          }
          relationships {
            image: field_image {
              uri {
                url
              }
              localFile {
                url
                publicURL
              }
            }
          }
        }
        box1: field_paragraph_repeater2 {
          ...ParagraphImageAndText
        }
        box2: field_items {
          ...ParagraphImageAndText
        }
        OGImage: field_image {
          localFile {
            url
            publicURL
          }
        }
      }
    }
  }
`
