import * as React from "react"
import /* Header, */ { LogoHeader } from "./header"
//import Footer from "./footer"

const Layout2 = ({ children, side = '<div></div>', classesSide = '' }) => {

  return (
    <main className="flex flex-col lg:flex-row w-full h-full min-h-screen justify-between">
      <div className="lg:w-1/2 h-full min-h-screen lg:px-8">
        <LogoHeader />
        {children}
      </div>
      <div className={"lg:w-1/2 bg-blue text-white min-h-screen lg:p-12 " + classesSide}>
        {side}
      </div>
    </main>
  )
}


export default Layout2
